import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HashMap } from '@common/angular/interfaces';
import {
  ProcurementCreateDto,
  ProcurementDefaultDto,
  ProcurementDto,
  ProcurementListRequestDto,
  ProcurementListResultDto
} from '@stock-flow/models';
import { Observable } from 'rxjs';
import { STOCK_FLOW_API_CONFIG } from '../../tokens';

@Injectable()
export class ProcurementService {
  constructor(
    private http: HttpClient,
    @Inject(STOCK_FLOW_API_CONFIG) private apiUrl: string
  ) {}

  getProcurements(
    request: ProcurementListRequestDto
  ): Observable<ProcurementListResultDto> {
    const options = {
      params: new HttpParams({ fromObject: <HashMap>request })
    };
    return this.http.get<ProcurementListResultDto>(
      `${this.apiUrl}/Procurements`,
      options
    );
  }

  getProcurement(id: string): Observable<ProcurementDto> {
    return this.http.get<ProcurementDto>(`${this.apiUrl}/Procurements/${id}`);
  }

  getDefaultValue(operationId: string): Observable<ProcurementDefaultDto> {
    return this.http.get<ProcurementDefaultDto>(
      `${this.apiUrl}/Procurements/Default/${operationId}`
    );
  }

  updateProcurement(procurement: ProcurementDto): Observable<ProcurementDto> {
    return this.http.put<ProcurementDto>(
      `${this.apiUrl}/Procurements/${procurement.id}`,
      procurement
    );
  }

  deleteProcurement(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/Procurements/${id}`);
  }

  deleteProcurements(ids: string[]): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/Procurements`, { body: ids });
  }

  createProcurement(
    procurement: ProcurementCreateDto
  ): Observable<ProcurementDto> {
    return this.http.post<ProcurementDto>(
      `${this.apiUrl}/Procurements`,
      procurement
    );
  }

  reCalculateEstimates(
    procurement: ProcurementDto
  ): Observable<ProcurementDto> {
    return this.http.post<ProcurementDto>(
      `${this.apiUrl}/Procurements/${procurement.id}/Calculate`,
      procurement
    );
  }

  shareProcurementReport(
    procurementId: string,
    recipientEmails: string[]
  ): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/Procurements/${procurementId}/ShareBuyOrderReport`,
      { recipientEmails }
    );
  }

  copyProcurement(procurementId: string): Observable<string> {
    return this.http.post<string>(
      `${this.apiUrl}/Procurements/${procurementId}/Copy`,
      {}
    );
  }
}
