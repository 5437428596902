<p-chips
  [formControl]="this.control"
  [formlyAttributes]="field"
  [placeholder]="props['placeholder']"
  [addOnTab]="props['addOnTab'] || true"
  [addOnBlur]="props['addOnBlur'] || true"
  [allowDuplicate]="props['allowDuplicate'] || false"
  separator=","
  (onAdd)="props['onAdd'] || onAdd($event)"
  (onRemove)="props['onRemove'] || onRemove($event)"
  class="w-full"
></p-chips>

